html,
body {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}


body {
  font-family: "Open Sans", sans-serif !important;
  color: #3A3A3A;
  line-height: 1.5;
  padding-top: 83px;
}

pre {
  display: inline-block !important;
  font-size: 14px !important;
  background-color: rgba(220, 220, 220, 0.5);
  padding: 6px 13px;
  position: relative;
  overflow: visible !important;

  &[copy-text-pre]:hover:after {
    content: "click to copy";
    position: absolute;
    width: 100px;
    text-align: center;
    top: -15px;
    left: calc(50% - 50px);
    background-color: rgba(34, 34, 34, 0.5);
    color: white;
    padding: 4px 8px;
    font-size: 1.1rem;
  }
}

.fp-header {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999999;
  background-color: white;
  box-shadow: 0rem 0.2rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  height: 80px;
  align-items: center;

  @media (max-width: 767.98px) {
    display: block;
  }

  .logo {
    img {
      width: 20.25rem !important;
    }

    padding-left: 2.3rem !important;
    width: 100% !important;
  }
}

.table {
  /* border-top-left-radius: 8px;
  border-top-right-radius: 8px; */
  border: 1px solid #E5E5E5 !important;
  margin: 1.5rem 0 3rem 0 !important;

  td,
  th {
    padding: 2rem 1.4rem !important;
    border-color: #E5E5E5;
  }

  tr {
    border: 0 !important;
  }

  :first-child {
    th {
      border: 0 !important;
      border-bottom: 1px solid #266FA6 !important;
    }
  }
}